import React from "react";

import type { NextPage, GetStaticProps } from "next";
import type { SSRConfig } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { FORBIDDEN_LOCALES, REVALIDATE } from "@/config";
import { Dirs } from "@/contentful/content-model/types";
import { layoutGateway } from "@/queries/gateways/layout";
import { pageGateway } from "@/queries/gateways/page";
import PageQuery from "@/queries/page.graphql";
import { Template } from "@/templates/page";
import type {
	BlogPost,
	BusinessInfoCollection,
	NavigationCollection,
	Page,
	PageCollection,
	Person,
	SubPage,
	TextCollection,
} from "@/types/contentful-api";
import { buildMetaTags, getSEOAsset, localizeCanonical } from "@/utils/seo";

type EntryListCollection = Record<string, SubPage[] | BlogPost[] | Person[]>;

type SellPageProps = SSRConfig & {
	url: string;
	data: {
		page: Page;
		entryListCollection: EntryListCollection;
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	};
};

// TODO: investigate amount of re-renders on page level
const SellPage: NextPage<SellPageProps> = ({ data, url }) => <Template data={data} url={url} />;

export const getStaticProps: GetStaticProps<SellPageProps> = async ({ locale }) => {
	if (FORBIDDEN_LOCALES.includes(locale)) {
		return {
			notFound: true,
			revalidate: REVALIDATE,
		};
	}

	try {
		const translations = await serverSideTranslations(locale);

		const pageData = await pageGateway<Page, { pageCollection: PageCollection }>(PageQuery, {
			locale,
			dir: Dirs.sell,
		});
		const layoutData = await layoutGateway({ locale });

		// MetaTags mapping
		pageData.page.meta = buildMetaTags({
			defaultMeta: layoutData.metaCollection.items[0],
			pageMeta: pageData.page.meta,
			titleOverride: pageData.page.headline,
			imageOverride: getSEOAsset(pageData.page.keyVisual),
		});

		const url = localizeCanonical(pageData.page.meta.robots, Dirs.sell, locale);

		return {
			props: {
				...translations,
				url,
				data: {
					...pageData,
					...layoutData,
				},
			},
			revalidate: REVALIDATE,
		};
	} catch (error: unknown) {
		console.error(error);
		return {
			notFound: true,
			revalidate: REVALIDATE,
		};
	}
};

export default SellPage;
